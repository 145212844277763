<template>
  <div>
    <div class="wrap pb-16">
      <page-info
        :name="showVotingCards ? $t('global.vote') : $t('global.management')"
        :description="$t('pages.contractPayoutSetting')"
      />

      <switcher
        @showVoting="
          (e) => {
            showVotingCards = e;
          }
        "
      />

      <buttons-menu />

      <proposal v-if="!showVotingCards" :actionType="2" @info="getInfo" />

      <voting-cards
        v-else
        :proprosals="proprosals"
        :actionType="2"
        :actionName="$t('actions.payoutSetting')"
        @info="getInfo"
      />

      <infoBox :data="info" />
    </div>
  </div>
</template>

<script>
import Proposal from "../components/proposal";
import ButtonsMenu from "../components/buttonsMenu";
import InfoBox from "../components/infoBox";
import PageInfo from "../components/pageInfo";
import VotingCards from "../components/votingCards";
import Switcher from "../components/switcher";
export default {
  components: {
    PageInfo,
    ButtonsMenu,
    VotingCards,
    InfoBox,
    Proposal,
    Switcher,
  },

  data() {
    return {
      info: {},
      showVotingCards: false,
    };
  },

  watch: {
    showVotingCards(value) {
      if (value) {
        this.getProprosals();
      }
    },

    blockchainConnected(active) {
      if (active) {
        this.getProprosals();
      }
    },
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },

    proprosals() {
      const { proprosals } = this.$store.state.web3;

      return proprosals;
    },

    blockchainConnected() {
      return this.$store.state.web3.active;
    },
  },

  methods: {
    getProprosals() {
      this.$store.commit("getProprosals", { actionType: 2, count: 10 });
    },

    getInfo(e) {
      this.info = e;
    },
  },

  mounted() {
    if (this.blockchainConnected) {
      this.getProprosals();
    }
  },
};
</script>

<style lang="scss" scoped></style>
